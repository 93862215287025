<template>
  <section
    class="n-section-primary"
    :class="{
      '!tw-pb-0': variant === variants.V3Blogs,
    }"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div
      class="tw-flex tw-w-full tw-flex-col"
      :class="variants.V2Faqs === variant ? 'tw-items-start tw-justify-start' : 'tw-items-center tw-justify-center'"
    >
      <div
        class="tw-flex tw-w-full tw-flex-col tw-space-y-4 md:tw-flex-row md:tw-space-x-4 md:tw-space-y-0 lg:-tw-mt-16"
        :class="variant === variants.V3Blogs ? 'tw-mb-5 tw-w-full' : 'tw-mb-0'"
      >
        <intro-section version="v4" :component="component" :variables="vars" :tabs="tabs" :active-tab="activeTab" />
      </div>

      <div v-if="variant === variants.V2Faqs" class="tw-my-5 tw-w-full">
        <search-box
          v-if="vars.isSearchActiveBool"
          ref="searchBox"
          v-model="faqSearchKeyword"
          :search-result-list="searchResultList"
          :search-method="onSearchFaqs"
          placeholder="Search"
        />
      </div>

      <div class="tw-w-full tw-py-10 tw-text-left lg:tw-py-20">
        <h2>
          <span v-if="guides">{{ guides.length }}</span>
          {{ vars.titleText }}
        </h2>
      </div>

      <div v-if="variant === variants.V1Guides" class="tw-grid tw-w-full tw-grid-cols-12 tw-gap-x-8 tw-gap-y-8">
        <div
          v-for="(guide, index) in guides"
          :key="`guide-${index}`"
          class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-4 xl:tw-col-span-3"
        >
          <theme4-components-guide-card
            :guide="guide"
            :component="component"
            @open-guide-popup="toggleGuidePopup(guide, true)"
          ></theme4-components-guide-card>
        </div>
        <popup v-if="selectedGuide && popupConfig.isActive" :config="popupConfig" custom-width="80%">
          <template #content>
            <div class="tw-w-full tw-text-black">
              <div
                class="tw-mx-auto tw-flex tw-w-full tw-flex-col tw-bg-white md:tw-w-[80%] lg:tw-w-full lg:tw-flex-row"
                style="border-radius: var(--rounded)"
              >
                <div class="tw-w-full lg:tw-max-w-[465px]">
                  <LibraryImage
                    :w-ratio="1"
                    :h-ratio="1.45"
                    :src="selectedGuide.popup_cover"
                    style="border-radius: var(--rounded)"
                  />
                </div>
                <div class="tw-flex tw-w-full tw-flex-col tw-p-6 md:tw-p-10 xl:tw-px-20">
                  <div class="tw-text-black" v-html="selectedGuide.popup_title"></div>
                  <library-form
                    v-if="form"
                    :form="form"
                    version="v2"
                    :component="component"
                    :custom-managed-form="managedFormVars"
                    :isFormNameVisible="false"
                  ></library-form>
                </div>
              </div>
            </div>
          </template>
        </popup>
      </div>

      <div v-if="variant === variants.V2Faqs" class="tw-w-full">
        <library-accordion v-if="!isFaqResultsLoading" :elements="faqs" />
        <loader v-show="isFaqResultsLoading" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import LibraryAccordion from '~/components/library/content/Accordion.vue';
import SearchBox from '~/components/common/SearchBox.vue';
import { SearchEngine } from '~/core/engines/search.engine';
import { sleep } from '~/helpers';
import Loader from '~/components/common/Loader.vue';
import BlogsPage from '~/components/theme5/blogs/BlogsPage.vue';
import IntroSection from './IntroSection.vue';
import Popup from '~/components/common/popup/Popup.vue';

export default defineNuxtComponent({
  name: 'Theme4KnowledgeHubPage',

  components: {
    Loader,
    SearchBox,
    LibraryAccordion,
    BlogsPage,
    IntroSection,
    Popup,
  },

  mixins: [ComponentMixin],

  data() {
    return {
      tabs: [
        /* {
          title: 'WRITTEN GUIDES',
          name: 'written-guides',
        }, */
        {
          title: 'VIDEO GUIDES',
          name: 'video-guides',
        },
        /*         {
          title: 'WRITTEN FAQS',
          name: 'written-faqs',
        }, */
        {
          title: 'VIDEO FAQS',
          name: 'video-faqs',
        },
        {
          title: 'Blog',
          name: 'blog',
        },
        {
          title: 'Area Guides',
          name: 'area-guides',
        },
      ],

      variants: {
        V1Guides: 'V1Guides',
        V2Faqs: 'V2Faqs',
        V3Blogs: 'V3Blogs',
      },

      faqSearchKeyword: '',
      searchResultList: [],
      isFaqResultsLoading: false,

      selectedGuide: null,
      popupConfig: {
        isActive: false,
      },
      form: null,
    };
  },

  created() {
    const route = useRoute();
    if (route.query.q && this.activeTab === 'faqs') {
      this.faqSearchKeyword = route.query.q;
    }
  },

  computed: {
    managedFormVars() {
      if (this.selectedGuide) {
        return {
          isNeuronManagedFormBool: this.selectedGuide.is_neuron_managed_form,
          formRedirectUrlText: this.selectedGuide.form_redirect_url,
          formInternalTitleForCustomerText: this.selectedGuide.form_internal_title_for_customer,
        };
      }
    },

    activeTab() {
      return this.vars.activeTabText;
    },

    activeSubTab() {
      const route = useRoute();

      if (route.query.type === 'buyers') {
        return 'buyers';
      } else if (route.query.type === 'tenants') {
        return 'tenants';
      } else if (route.query.type === 'sellers') {
        return 'sellers';
      } else if (route.query.type === 'landlords') {
        return 'landlords';
      }
      return '';
    },

    subTitle() {
      return this.vars.subTabTitleText;
    },

    subTabs() {
      return [
        {
          title: 'All',
          name: '',
        },
        {
          title: 'Buyers',
          name: 'buyers',
        },
        {
          title: 'Tenants',
          name: 'tenants',
        },
        {
          title: 'Sellers',
          name: 'sellers',
        },
        {
          title: 'Landlords',
          name: 'landlords',
        },
      ];
    },

    guides() {
      if (this.activeSubTab) {
        const validTypes = ['buyers', 'tenants', 'landlords', 'sellers'];

        return this.groupedVariables.guides.filter((guide) => {
          if (validTypes.includes(this.activeSubTab)) {
            return guide.group_type === this.activeSubTab;
          }

          return false;
        });
      }

      return this.groupedVariables.guides;
    },

    faqs() {
      const route = useRoute();
      if (route.query.q) {
        const keyword = route.query.q as string;

        const searchEngine = new SearchEngine();

        return searchEngine.searchInArray(this.groupedVariables.faqs, ['title', 'description'], keyword);
      }
      return this.groupedVariables.faqs;
    },
  },

  methods: {
    async onSearchFaqs() {
      this.isFaqResultsLoading = true;
      const router = useRouter();

      await sleep(1000);

      await router.replace({
        query: {
          q: this.faqSearchKeyword,
        },
      });
      this.isFaqResultsLoading = false;
    },

    toggleGuidePopup(guide = null, value = false) {
      if (guide.popup_title) {
        this.selectedGuide = guide;
        this.form = this.parseFormV2(guide.popup);
        this.popupConfig.isActive = value;
      }
    },
  },
});
</script>

<style scoped>
.n-tertiary:hover {
  background-color: white;
  color: unset;
  border-color: var(--c-primary);
}

::v-deep(.accordion-list:not(:last-child)) {
  border-bottom: 1px solid var(--c-border);
}

::v-deep(.blogs-page) {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
</style>
