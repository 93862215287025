<template>
  <section
    class="tw-h-screen lg:tw-h-[70vh]"
    :style="`color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-secondary)'}`"
  >
    <div
      v-if="variants.v1 === variant"
      class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-center"
    >
      <NuxtImg
        :src="isMobileDevice ? vars.mobileBackgroundImage || vars.backgroundImage : vars.backgroundImage"
        class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-object-center"
      />
      <div class="tw-absolute tw-inset-0 tw-bg-black tw-opacity-50"></div>
      <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="500" is="div">
        <div class="tw-relative tw-max-w-2xl tw-px-2 tw-py-20 tw-text-center">
          <h3 class="tw-mb-5 tw-pt-3 tw-text-white">
            {{ vars.titleText }}
          </h3>
          <p
            v-for="(item, index) in descriptions"
            :key="`take-action-description-${index}`"
            class="tw-mb-8 tw-text-white"
          >
            {{ item.description }}
          </p>
          <nuxt-link :class="`n-${vars.buttonTypeText}`" :to="vars.buttonLinkUrl" :external="true">
            {{ vars.buttonLabelText }}
          </nuxt-link>
        </div>
      </MotionGroup>
    </div>
    <div
      v-if="variants.v2 === variant"
      class="tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-text-center"
    >
      <NuxtImg
        :src="isMobileDevice ? vars.mobileBackgroundImage || vars.backgroundImage : vars.backgroundImage"
        class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-object-center"
      />
      <div
        class="tw-absolute tw-inset-0 tw-opacity-50"
        :style="`background-color: var(--c-${vars.backgroundLayerColor})`"
      ></div>
      <MotionGroup :preset="vars.animationText ? vars.animationText : 'popVisible'" :duration="500" is="div">
        <div>
          <div class="tw-relative tw-max-w-2xl tw-px-2 tw-py-20 tw-text-center">
            <h3 class="tw-mb-5 tw-pt-3 tw-text-white">
              {{ vars.titleText }}
            </h3>
            <p
              v-for="(item, index) in descriptions"
              :key="`take-action-description-${index}`"
              class="tw-mb-8 tw-text-white"
            >
              {{ item.description }}
            </p>
            <nuxt-link :class="`n-${vars.buttonTypeText}`" :to="vars.buttonLinkUrl" :external="true">
              {{ vars.buttonLabelText }}
            </nuxt-link>
          </div>
        </div>
      </MotionGroup>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'TakeAction',

  mixins: [ComponentMixin],

  data() {
    return {
      variants: {
        v1: 'v1',
        v2: 'v2',
      },
    };
  },

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },
  },
});
</script>
