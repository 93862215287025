<template>
  <section
    class="n-section-primary tw-rounded-lg !tw-pb-2 tw-transition-all"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-py-16">
      <h2 class="tw-mb-4">{{ vars.titleText }}</h2>
      <p class="tw-mb-8">{{ vars.descriptionText }}</p>

      <div class="tw-flex tw-flex-col tw-items-start tw-gap-4">
        <button
          v-if="!isLocationShared"
          class="n-primary disabled:cursor-not-allowed tw-group tw-relative tw-flex tw-items-center tw-gap-3 tw-rounded-full tw-px-8 tw-py-4 tw-transition-all disabled:tw-opacity-50"
          :class="{
            'no-hover-effect tw-hidden tw-opacity-50': isFetchingLocation || locationServicesDisabled,
            'tw-hidden hover:tw-scale-105 hover:tw-transform': !isFetchingLocation && !locationServicesDisabled,
          }"
          @click="getUserLocation()"
          :disabled="isFetchingLocation || locationServicesDisabled"
        >
          <template v-if="isFetchingLocation">
            <loader :loader-size="24" :loader-padding="'0px'" loader-color="var(--c-border)" />
            <span>Getting Location</span>
          </template>
        </button>

        <div
          v-if="isLocationShared"
          class="tw-flex tw-items-center tw-gap-2 tw-rounded-lg tw-bg-green-50 tw-px-4 tw-py-3 tw-text-green-600"
        >
          <svg class="tw-h-5 tw-w-5 tw-shrink-0" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>
          <p class="tw-font-medium">
            Your location has been successfully shared! Now you can start exploring homes around you. 🎉
          </p>
        </div>

        <div
          v-if="locationError"
          class="tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded-lg tw-bg-red-50 tw-px-4 tw-py-2 tw-text-red-500"
        >
          <svg class="tw-h-5 tw-w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
          <div>
            <p class="tw-font-medium">{{ locationError }}</p>

            <div v-if="locationServicesDisabled" class="tw-mt-2">
              <p>
                This feature requires access to your location information to work accurately. To continue, please enable
                location services in your browser or device settings.
                <br />
                Are you not sure how to do this? We have got you covered! Check out our guides below.
              </p>
              <div v-if="isIOS" class="tw-mt-1">
                <nuxt-link
                  :external="true"
                  to="https://support.apple.com/en-us/102515#:~:text=You%20can%20turn%20Location%20Services,access%20to%20Location%20Services%20data"
                  target="_blank"
                  class="n-link"
                >
                  Guide for iOS
                </nuxt-link>
              </div>
              <div v-else-if="isAndroid" class="tw-mt-1">
                <nuxt-link
                  :external="true"
                  to="https://guidebooks.google.com/android/changesettingspermissions/turnondevicelocation"
                  target="_blank"
                  class="n-link"
                >
                  Guide for Android
                </nuxt-link>
              </div>
              <div v-else class="tw-mt-1">
                <nuxt-link
                  :external="true"
                  to="https://support.apple.com/en-us/102515#:~:text=You%20can%20turn%20Location%20Services,access%20to%20Location%20Services%20data"
                  target="_blank"
                  class="n-link tw-mr-3"
                >
                  Guide for iOS
                </nuxt-link>
                <nuxt-link
                  :external="true"
                  to="https://guidebooks.google.com/android/changesettingspermissions/turnondevicelocation"
                  target="_blank"
                  class="n-link"
                >
                  Guide for Android
                </nuxt-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';
import { useAddressesStore } from '~/units/addresses/store';
import Loader from '~/components/common/Loader.vue';
import { useRoute, useRouter } from 'vue-router';

interface UserLocation {
  lat: number;
  lng: number;
  location: string;
  place_id: string;
  bounds: {
    northeast: { lat: number; lng: number };
    southwest: { lat: number; lng: number };
  };
}

export default defineNuxtComponent({
  name: 'QRProperties',

  mixins: [ComponentMixin],

  components: { Loader },

  data() {
    return {
      userLocation: null as UserLocation | null,
      locationError: '',
      isLocationShared: false,
      isFetchingLocation: false,
      locationServicesDisabled: false,
      router: null,
      route: null,
      isIOS: false,
      isAndroid: false,
    };
  },

  async mounted() {
    this.router = useRouter();
    this.route = useRoute();

    this.detectDeviceType();

    if (this.route.query.locations) {
      try {
        const locationData = JSON.parse(this.route.query.locations as string);
        if (locationData && locationData.length > 0) {
          const location = locationData[0];
          this.userLocation = {
            lat: location.lat,
            lng: location.lng,
            location: location.location,
            place_id: location.place_id,
            bounds: location.bounds,
          };
          this.isLocationShared = true;
        }
      } catch (e) {
        console.error('Error parsing location data from URL', e);
      }
    } else {
      await this.getUserLocation();
    }
  },

  methods: {
    detectDeviceType() {
      const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

      this.isIOS = /iPad|iPhone|iPod/.test(userAgent) && !(window as any).MSStream;

      this.isAndroid = /android/i.test(userAgent);
    },

    async getUserLocation() {
      if (!navigator.geolocation) {
        this.locationError = 'Location services are not supported by your browser';
        this.locationServicesDisabled = true;
        return;
      }

      this.locationError = '';
      this.locationServicesDisabled = false;
      this.isFetchingLocation = true;

      try {
        const position = await new Promise<GeolocationPosition>((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const addressesStore = useAddressesStore();
        const result = await addressesStore.getGeocodeByLatLong(position.coords.latitude, position.coords.longitude);

        if (!result?.data.geometry?.viewport && !result?.data.geometry?.location) {
          throw new Error('Invalid location data received');
        }

        this.userLocation = {
          lat: result.data.geometry.location.lat,
          lng: result.data.geometry.location.lng,
          location: result.data.formatted_address,
          place_id: result.data.place_id,
          bounds: result.data.geometry.viewport,
        };

        this.isLocationShared = true;

        this.$emit('location-obtained', this.userLocation);

        this.updateUrlWithLocationData();
      } catch (error) {
        if (error instanceof GeolocationPositionError && error.code === 1) {
          this.locationError = 'Your location services are disabled';
          this.locationServicesDisabled = true;
        } else {
          this.locationError = 'Your location services are disabled';
          this.locationServicesDisabled = true;
          console.error('Error getting location:', error);
        }

        this.$emit('location-error', this.locationError);
      } finally {
        this.isFetchingLocation = false;
      }
    },

    updateUrlWithLocationData() {
      if (!this.userLocation || !this.router || !this.route) return;

      const locationObj = {
        bounds: this.userLocation.bounds,
        lat: this.userLocation.lat,
        lng: this.userLocation.lng,
        location: this.userLocation.location,
        location_type: 'APPROXIMATE',
        place_id: this.userLocation.place_id,
        distance: 0,
      };

      const query = {
        locations: [locationObj],
        is_available: true,
        sort: 'best_match',
      };

      const queryParams = Object.entries(query).reduce(
        (acc, [key, value]) => {
          acc[key] = JSON.stringify(value);
          return acc;
        },
        {} as Record<string, string>,
      );

      this.router
        .replace({
          path: this.route.path,
          query: {
            ...this.route.query,
            ...queryParams,
          },
        })
        .then(() => {
          this.$emit('location-updated', locationObj);

          window.dispatchEvent(
            new CustomEvent('neuron:location-updated', {
              detail: locationObj,
              bubbles: true,
            }),
          );

          setTimeout(() => {
            window.dispatchEvent(new Event('popstate'));
          }, 100);
        })
        .catch((error) => {
          console.error('Error updating URL with location data:', error);
        });
    },
  },
});
</script>

<style scoped>
button.n-primary:hover svg path {
  fill: var(--c-primary);
}

/* Yeni stil ekleyelim */
:deep(.no-hover-effect.n-primary:hover) {
  background-color: var(--c-secondary) !important;
  color: white !important;
}

/* Add styles for success message */
.tw-bg-green-50 {
  background-color: rgba(240, 253, 244, 1);
}

.tw-text-green-600 {
  color: rgba(22, 163, 74, 1);
}
</style>
