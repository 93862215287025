<template>
  <section class="!tw-px-0 lg:tw-h-[70vh]">
    <div
      class="tw-relative tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-px-4 tw-text-center md:tw-px-6"
    >
      <NuxtImg
        :src="vars.backgroundImage"
        class="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-object-cover tw-object-center"
      />
      <div
        class="tw-absolute tw-inset-0 tw-opacity-70"
        :style="`background-color: var(--c-${vars.backgroundLayerColor}); `"
      ></div>
      <div
        class="tw-relative tw-w-full tw-max-w-2xl tw-py-12 md:tw-py-16 lg:tw-py-20"
        :style="`color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-secondary)'}`"
      >
        <MotionGroup :preset="vars.animationText ? vars.animationText : 'fadeVisible'" :duration="800" is="div">
          <div
            v-html="vars.titleHtml"
            class="tw-mb-4 tw-flex tw-flex-wrap tw-justify-center tw-gap-2 tw-text-center md:tw-mb-5 md:tw-gap-3 lg:tw-mb-8 lg:tw-gap-4"
          ></div>

          <div class="tw-space-y-4 md:tw-space-y-6">
            <p
              v-for="(description, index) in descriptions"
              :key="`take-action-description-${index}`"
              class="tw-text-sm md:tw-text-base"
            >
              {{ description.description }}
            </p>
          </div>

          <div
            class="tw-mt-8 tw-flex tw-flex-col tw-items-center tw-justify-around tw-gap-4 md:tw-flex-row md:tw-gap-6"
          >
            <h4 v-for="(item, index) in items" :key="index" class="tw-text-sm md:tw-text-base lg:tw-text-lg">
              {{ item.title }}
            </h4>
          </div>
        </MotionGroup>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'ContentBanner',

  mixins: [ComponentMixin],

  computed: {
    descriptions() {
      return this.groupedVariables.descriptions;
    },

    items() {
      return this.groupedVariables.items;
    },
  },
});
</script>
