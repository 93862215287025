<template>
  <section
    class="n-section-primary"
    :style="`background-color: var(--c-${vars.backgroundColor}); color: ${vars.textColor ? vars.textColor : 'var(--c-text-primary)'}`"
  >
    <div v-if="variants.GoogleReviews === variant" class="tw-w-full tw-text-center">
      <h3 class="tw-pt-14">{{ vars.titleText }}</h3>
      <div class="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-gap-10 tw-py-14">
        <Swiper
          :modules="[SwiperPagination]"
          :pagination="true"
          :slides-per-view="1"
          :space-between="30"
          effect="fade"
          :clickable="true"
          :loop="true"
          :breakpoints="{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            1336: {
              slidesPerView: 3,
              spaceBetween: 24,
            },
          }"
          class="tw-w-full tw-max-w-lg lg:tw-max-w-full"
        >
          <SwiperSlide v-for="(review, index) in reviews" :key="index">
            <div
              class="tw-flex tw-h-full tw-min-h-[400px] tw-flex-col tw-gap-3 tw-rounded-lg tw-border-2 tw-p-10 tw-text-start"
            >
              <div class="tw-flex tw-items-center tw-gap-2">
                <svg
                  v-for="n in review.evaluation_value"
                  :key="n"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                    fill="#aead66"
                  />
                </svg>
              </div>
              <h6>{{ review.person_name }}</h6>
              <span class="tw-font-bold">{{ review.date }}</span>
              <p class="tw-max-h-64 tw-overflow-y-auto tw-pt-4 lg:tw-max-h-56">{{ review.content }}</p>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
    <div
      v-if="variants.GoogleReviewsV2 === variant"
      class="tw-flex tw-w-full tw-flex-col tw-items-center tw-text-center"
    >
      <h2 class="tw-pt-14">{{ vars.titleText }}</h2>
      <div
        class="tw-relative tw-flex tw-h-full tw-w-full tw-max-w-lg tw-flex-col tw-items-center tw-gap-10 tw-py-14 lg:tw-max-w-full"
      >
        <div class="content-container">
          <MotionGroup
            :preset="vars.animationText ? vars.animationText : 'slideVisibleBottom'"
            :duration="700"
            is="div"
          >
            <div class="swiper-wrapper">
              <Swiper
                :modules="[SwiperPagination]"
                @slideChange="onSlideChange"
                @swiper="onSwiper"
                :slides-per-view="1"
                :space-between="30"
                :loop="true"
                effect="fade"
                :clickable="true"
                :breakpoints="{
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                  },
                  1336: {
                    slidesPerView: 3,
                    spaceBetween: 24,
                  },
                }"
                class="tw-w-full"
              >
                <SwiperSlide v-for="(review, index) in reviews" :key="index">
                  <div
                    class="n-testimonial-card tw-flex tw-h-full tw-min-h-[400px] tw-flex-col tw-gap-3 tw-p-10 tw-text-start"
                    style="border-radius: var(--rounded)"
                  >
                    <div class="tw-flex tw-justify-between">
                      <span class="tw-font-bold">{{ review.date }}</span>
                      <div class="tw-flex tw-items-center tw-gap-2">
                        <svg
                          v-for="n in review.evaluation_value"
                          :key="n"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10 1.25L12.472 7.5H19L13.764 10.972L15.236 17.5L10 13.444L4.764 17.5L6.236 10.972L1 7.5H7.528L10 1.25Z"
                            fill="var(--c-secondary)"
                          />
                        </svg>
                      </div>
                    </div>
                    <h4>{{ review.person_name }}</h4>
                    <p class="tw-line-clamp-[8] tw-pt-4">{{ review.content }}</p>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </MotionGroup>
        </div>
        <LibrarySliderPagination
          :swiper="swiper"
          variant="v3"
          :can-paginate-back="canPaginateBack"
          :can-paginate-next="canPaginateNext"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Testimonials',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: true,
      canPaginateNext: true,
      reviews: null,
      variants: {
        GoogleReviews: 'GoogleReviews',
        GoogleReviewsV2: 'GoogleReviewsV2',
      },
    };
  },

  created() {
    const parsedReviews = this.parseFormV2(this.vars.googleReviews);
    this.reviews = parsedReviews?.reviews;
  },

  computed: {
    buttons() {
      return this.groupedVariables.buttons;
    },
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      const isAtStart = this.swiper.isBeginning;
      const isAtEnd = this.swiper.isEnd;
      if (isAtStart) this.canPaginateBack = false;
      else this.canPaginateBack = true;
      if (isAtEnd) this.canPaginateNext = false;
      else this.canPaginateNext = true;
    },
  },
  mounted() {
    this.onSlideChange();
  },
});
</script>
