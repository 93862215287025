<template>
  <Properties :component="component">
    <template #library-properties>
      <section
        class="properties tw-grid tw-grid-cols-12 tw-items-start tw-gap-6"
        :style="vars.backgroundColor ? `background-color: var(--c-${vars.backgroundColor})` : ''"
      >
        <h1>{{ vars.titleText }}</h1>
        <div
          v-if="isExtendedFilterPopupVisible"
          class="tw-fixed tw-left-0 tw-top-0 tw-z-20 tw-h-screen tw-w-full tw-bg-black tw-opacity-40"
        ></div>

        <div class="tw-col-span-12 tw-w-full">
          <div class="tw-block xl:tw-hidden">
            <lazy-library-properties-search-box
              v-model:filters="filters"
              v-model:locations="locations"
              v-model:search-input="searchInput"
              :search-type="searchType"
              @on-search="onSearch()"
              @open-extended-popup="openExtendedPopup()"
              :buy="vars.buyBool"
              :rent="vars.rentBool"
              version="v2"
              :title="vars.titleText"
            />
          </div>
          <div class="tw-hidden xl:tw-block">
            <lazy-library-properties-search-box
              v-model:filters="filters"
              v-model:locations="locations"
              v-model:search-input="searchInput"
              :search-type="searchType"
              @on-search="onSearch()"
              @open-extended-popup="openExtendedPopup()"
              :agent-type="vars.agentType"
              :version="vars.searchboxVersionText"
              :buy="vars.buyBool"
              :rent="vars.rentBool"
              :title="vars.titleText"
            />
          </div>

          <template v-if="sidebarComponents && sidebarComponents.length">
            <div class="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-pb-4 tw-pt-10">
              <p>{{ filterSummary }}</p>

              <div class="tw-flex tw-items-center tw-gap-6">
                <div
                  v-if="isApplicantExist"
                  class="tw-me-3 tw-flex tw-cursor-pointer tw-items-center"
                  @click="onClickedProfile()"
                >
                  <span class="nc-icon-profile-user-1 tw-me-2" style="font-size: 24px"> </span>
                  <p class="tw-mr-2 tw-truncate tw-capitalize">
                    {{ contactSession.name }}
                  </p>
                </div>

                <button v-if="!isApplicantExist" class="n-primary" @click="setupHeadsUpAlerts()">
                  Heads Up Property Alerts
                </button>
                <button v-if="isApplicantExist" class="n-primary" @click="updateHeadsUpAlerts()">
                  Update Heads Up Alert
                </button>
              </div>
            </div>
            <div class="tw-py-4">
              <hr />
            </div>
          </template>
        </div>

        <div
          class="tw-col-span-12"
          :class="{ 'tw-order-2 xl:tw-col-span-8': sidebarComponents && sidebarComponents.length }"
        >
          <div v-if="filterSummary">
            <template v-if="sidebarComponents && sidebarComponents.length === 0">
              <div class="tw-flex tw-items-center tw-justify-between tw-gap-4 tw-py-4">
                <p>{{ filterSummary }}</p>

                <div class="tw-flex tw-items-center tw-gap-4">
                  <div
                    v-if="isApplicantExist"
                    class="tw-me-3 tw-flex tw-cursor-pointer tw-items-center"
                    @click="onClickedProfile()"
                  >
                    <span class="nc-icon-profile-user-1 tw-me-2" style="font-size: 24px"> </span>
                    <p class="tw-mr-2 tw-truncate tw-capitalize">
                      {{ contactSession.name }}
                    </p>
                  </div>

                  <button
                    v-if="!isApplicantExist && !vars.customButtonLabelText"
                    class="n-primary"
                    @click="setupHeadsUpAlerts()"
                  >
                    Heads Up Property Alerts
                  </button>
                  <button
                    v-if="isApplicantExist && !vars.customButtonLabelText"
                    class="n-primary"
                    @click="updateHeadsUpAlerts()"
                  >
                    Update Heads Up Alert
                  </button>

                  <button
                    v-if="!isApplicantExist && vars.customButtonLabelText"
                    class="n-primary"
                    @click="setupHeadsUpAlerts()"
                  >
                    {{ vars.customButtonLabelText }} Property Alerts
                  </button>
                  <button
                    v-if="isApplicantExist && vars.customButtonLabelText"
                    class="n-primary"
                    @click="updateHeadsUpAlerts()"
                  >
                    Update {{ vars.customButtonLabelText }} Alert
                  </button>
                </div>
              </div>
              <div class="tw-py-4">
                <hr />
              </div>
            </template>

            <div class="tw-py-5" v-if="isLoading">
              <loader />
            </div>

            <div v-else-if="!isLoading && (highlightedProperties.length || properties.length)">
              <div v-if="locations.length > 0" class="row g-0 mb-3">
                <div class="col-12">
                  <location-boxes v-model:locations="locations" :is-first-location-excluded="true" />
                </div>
              </div>
              <div v-if="vars.isFineAndCountryBool && (filters.min_price >= 500000 || filters.max_price >= 500000)">
                <fine-and-country
                  :static="{
                    variant: 'v3',
                    bgColor: 'section_black',
                    logo: 'https://files.beercocks.neuronwebsites.co.uk/fineandcountry-pu6pe6oyu.webp',
                    title:
                      '<p class=\'n-title-primary\'>This search criteria is mainly covered by our <span class=\'n-title-tertiary\'> Fine and Country </span> branch. <span class=\'n-title-tertiary\'> Go to their branch page to find out more. </span> </p>',
                    buttons: [
                      {
                        label: 'Search Fine and Country',
                        link: 'https://fineandcountry.co.uk/willerby-and-beverley-estate-agents',
                      },
                      {
                        label: 'Register with Fine and Country',
                        link: 'https://fineandcountry.web.lifesycle.co.uk/properties/register',
                      },
                      { label: 'View Fine and Country', link: 'https://beercocks.com/our-offices/fine-&-country' },
                    ],
                  }"
                />
              </div>

              <template v-if="highlightedProperties.length">
                <div
                  v-if="highlightedProperties.length > 0"
                  class="row g-0 mb-5 d-flex flex-row justify-content-between"
                >
                  <div class="col-md-8 col-12 tw-mb-4 md:tw-mb-0">
                    <lazy-library-title tag="h1" :text="`New matches: ${newMatchesPropertyCount} properties`">
                    </lazy-library-title>
                  </div>
                  <div class="col-md-4 col-xl-2 col-12 d-flex justify-content-end">
                    <lazy-library-properties-sort-box v-model:filter-criteria="filterCriteria" @on-sort="sort()" />
                  </div>
                </div>
                <lazy-library-properties-page-list
                  v-if="highlightedProperties.length > 0"
                  :component="component"
                  :properties="highlightedProperties"
                />
              </template>

              <div class="properties-match-card tw-flex tw-items-center tw-justify-between">
                <h3 v-if="highlightedProperties.length === 0">{{ count }} Results</h3>
                <h3 v-else-if="highlightedProperties.length > 0 && properties.length">
                  Existing matches:
                  {{ count }} - {{ highlightedProperties.length }} Results
                </h3>

                <library-properties-sort-box
                  v-if="highlightedProperties.length === 0"
                  v-model:filter-criteria="filterCriteria"
                  version="v2"
                  @on-sort="sort()"
                />
              </div>
              <lazy-library-properties-page-list
                :theme="theme"
                :component="component"
                :properties="properties"
                :columns="vars.columnsNumber"
                :pre-market-property-feature="vars.preMarketPropertyFeatureBool"
                :version="vars.propertyListItemVersionText"
              />
              <div v-if="isPropertiesGridLoadMoreActive" class="tw-flex tw-items-center tw-justify-center tw-py-8">
                <button class="n-secondary" @click="loadMore()">LOAD MORE</button>
              </div>
            </div>

            <div v-else-if="properties && properties.length === 0" class="tw-h-[40vh] tw-py-5">
              <lazy-theme4-base-not-found title="Unfortunately, there are no properties matching your search criteria.">
                <template #description>
                  Please
                  <nuxt-link to="/heads-up-alerts/register" :external="true" class="n-link"
                    ><u class="unknown-user-text text">setup a Heads Up property alert</u></nuxt-link
                  >
                  to be kept up to date in the future with new suggested properties that are based around your specific
                  criteria.
                </template>
              </lazy-theme4-base-not-found>
            </div>

            <advanced-search
              v-if="!isMobile && isExtendedFilterPopupVisible"
              v-model:filters="filters"
              v-model:is-available="searchInput.is_available"
              :search-type="searchType"
              @on-open-all-styles-popup="allStylesConfig.isActive = true"
              @close-advanced-filter="closeAdvancedFilter()"
              theme="theme4"
              :component="component"
              @open-extended-popup="turnOffExtendedPopup()"
            />

            <popup
              v-if="isMobile"
              :config="advancedSearchConfig"
              custom-width="auto"
              :custom-wrapper-class="propertiesConfig.advancedSearchMobilePopupBackgroundClass"
            >
              <template #content>
                <advanced-search
                  v-if="isExtendedFilterPopupVisible"
                  v-model:filters="filters"
                  v-model:is-available="searchInput.is_available"
                  :search-type="searchType"
                  @on-open-all-styles-popup="allStylesConfig.isActive = true"
                  @on-searched="onSearch()"
                  @close-advanced-filter="closeAdvancedFilter()"
                  theme="theme4"
                />
              </template>
            </popup>

            <popup v-if="allStylesConfig.isActive" v-model:config="allStylesConfig">
              <template #content>
                <all-property-types-styles
                  v-model:selected-type-styles="filters.property_types"
                  @close="allStylesConfig.isActive = false"
                  variant="v2"
                />
              </template>
            </popup>

            <popup
              v-if="applicantProfilesPopupConfig.isActive"
              v-model:config="applicantProfilesPopupConfig"
              custom-width="500px"
              class="applicant-profile-popup"
            >
              <template #content>
                <applicant-profiles
                  :search-type="searchType"
                  @on-selected-new-applicant="onSelectedNewApplicant()"
                  :is-visible-update-button="vars.applicantProfilesUpdateButtonBool"
                />
              </template>
            </popup>
          </div>
        </div>

        <div v-if="sidebarComponents && sidebarComponents.length" class="tw-order-3 tw-col-span-12 xl:tw-col-span-4">
          <div class="tw-mt-4 tw-block tw-pb-8">
            <!-- TODO: Dynamic Sidebar for Theme -->
            <theme6-available-components-for-sidebar
              key="property-sidebar"
              key-prefix="property-sidebar"
              :specific-components="sidebarComponents"
            />
          </div>
        </div>
      </section>
    </template>
  </Properties>
</template>

<script lang="ts">
import Properties from '~/components/library/Properties.vue';
import { ComponentMixin } from '~/mixins/component.mixin';
import { useRoute, useRouter } from 'vue-router';

export default defineNuxtComponent({
  name: 'PropertiesAroundMe',

  mixins: [ComponentMixin],

  extends: Properties,

  components: {
    Properties,
  },

  data() {
    return {
      searchInput: {
        mile: 0,
      },
    };
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    return {
      route,
      router,
    };
  },

  mounted() {
    if (this.route.query.locations) {
      this.processLocationDataFromUrl();
    }

    window.addEventListener('popstate', this.handlePopState);

    window.addEventListener('neuron:location-updated', this.handleLocationUpdate);

    this.$watch(
      () => this.route.query,
      (newQuery) => {
        if (newQuery.locations) {
          this.$nextTick(() => {
            this.processLocationDataFromUrl();
          });
        }
      },
      { deep: true },
    );
  },

  beforeUnmount() {
    // Clean up event listeners
    window.removeEventListener('popstate', this.handlePopState);
    window.removeEventListener('neuron:location-updated', this.handleLocationUpdate);
  },

  methods: {
    handlePopState() {
      this.processLocationDataFromUrl();
    },

    handleLocationUpdate(event) {
      this.processLocationDataFromUrl();
    },

    processLocationDataFromUrl() {
      try {
        if (!this.route || !this.route.query.locations) return;

        const locationsData = JSON.parse(this.route.query.locations as string);
        if (locationsData && locationsData.length > 0) {
          this.locations = locationsData;

          if (this.route.query.is_available) {
            this.searchInput.is_available = JSON.parse(this.route.query.is_available as string);
          }

          if (this.route.query.sort) {
            this.filterCriteria = JSON.parse(this.route.query.sort as string);
          }

          if (typeof this.onSearch === 'function') {
            this.onSearch();
          } else {
            console.error('onSearch method is not available');
          }
        }
      } catch (error) {
        console.error('Error processing location data from URL:', error);
      }
    },
  },
});
</script>
